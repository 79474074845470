import './bookingSummary.scss';

import { VehicleType, VehicleTypeDisplayName } from '@transferz/models';
import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'usehooks-ts';

import { ReactComponent as Bags } from '../../assets/svgs/bags.svg';
import { ReactComponent as Info } from '../../assets/svgs/info-grey.svg';
import { ReactComponent as Pen } from '../../assets/svgs/pen.svg';
import { ReactComponent as People } from '../../assets/svgs/people.svg';
import { useSessionStorage } from '../../hooks/sessionStorage/useSessionStorage';
import { useBookingContext } from '../../hooks/useBookingContext';
import { useLocalize } from '../../hooks/useLocalize';
import { useResolveGoogleAddress } from '../../hooks/useResolveGoogleAddress';
import { isSharedVehicleCategory } from '../../routes/vehicleSelection/VehicleSelection';
import { formatDate } from '../../utils/utils';
import { getQueryStringParams } from '../../utils/utils';
import { GeoDirection } from '../geoDirection/GeoDirection';
import PriceBreakdown from '../priceBreakdown/PriceBreakdown';
import SharedVehicleInformationPopup from '../vehicleCategoryInformationPopup/SharedVehicleInformationPopup';

export const BookingSummary: React.FC<any> = ({ data, queryData, onOriginAndDestinationResolved, center }) => {
  const { translate: t, locale } = useLocalize();
  const navigate = useNavigate();
  const { pathname: currentPathName } = useLocation();
  const urlParams: any = getQueryStringParams(window.location.search);
  const [initialUrl, setInitialUrl] = useSessionStorage('initialUrl');
  const { preferredCurrencyCode, setShowWidget } = useBookingContext();
  const isMobile = useMediaQuery('(max-width: 576px)');
  const [placeId, setPlaceId] = useState(queryData.placeId);

  const resolveGoogleAddress = useResolveGoogleAddress();

  useEffect(() => {
    if (urlParams.initialUrl) {
      setInitialUrl(urlParams.initialUrl);
    } else if (initialUrl.indexOf('?') > -1) {
      let url = '';
      const originFromInitialUrl = initialUrl.slice(0, initialUrl.indexOf('?'));
      const searchParamsFromInitialUrl = initialUrl.slice(initialUrl.indexOf('?'));
      const params: any = getQueryStringParams(searchParamsFromInitialUrl);

      if (params.preferredCurrencyCode) {
        params.preferredCurrencyCode = preferredCurrencyCode;
      }

      if (params.language) {
        params.language = locale;
      }

      Object.keys(params).forEach((key: any) => {
        url += `${url.includes('?') ? '&' : '?'}${key}=${params[key]}`;
      });

      setInitialUrl(originFromInitialUrl + url);
    }
    // eslint-disable-next-line
  }, [preferredCurrencyCode, locale]);

  const {
    hubName,
    directionality,
    destination,
    adultPassengers,
    passengers,
    luggage,
    inboundPickup,
    outboundPickup,
    isRoundTrip,
    vehicleCategory,
    vehicleModels,
    price,
    initialPrice,
    pricePerPassenger,
    childPassengers,
    infantPassengers,
  } = queryData;

  useEffect(() => {
    const fetchData = async () => {
      let placeId = queryData.placeId || (queryData.destination?.placeId && JSON.parse(queryData.destination?.placeId));
      if (!placeId && queryData.destination?.address) {
        const googleAddress = await resolveGoogleAddress(queryData.destination.address);
        placeId = googleAddress?.place_id;
      }
      setPlaceId(placeId);
    };
    fetchData();
  }, [queryData]);

  const travelAddons = useMemo(() => {
    let travelAddons = typeof queryData.travelAddons === 'string' ? JSON.parse(queryData.travelAddons) : queryData.travelAddons;
    if (travelAddons?.length > 1) {
      travelAddons = travelAddons.sort((a: any, b: any) => a.type.toLowerCase().localeCompare(b.type.toLowerCase()));
      const meetIndex = travelAddons?.findIndex((item: any) => item.type === 'MEET_GREET');
      if (meetIndex > -1) {
        const meetObject = travelAddons?.splice(meetIndex, 1)[0];
        travelAddons?.unshift(meetObject);
      }
    }
    return travelAddons;
  }, [queryData.travelAddons]);

  let {
    primaryContrastColor,
    primaryColor,
  } = window.tz_globalConfigs?.styling;
  const { partnerStylingOverrides } = window.tz_globalConfigs;

  primaryColor = '#fff';

  const bookingSummaryCustomStyles = {
    backgroundColor: partnerStylingOverrides?.['whitelabel.menu.backgroundColor'] || primaryColor || '#fc3',
    '--text-color': partnerStylingOverrides?.['whitelabel.menu.textColor'] || primaryContrastColor || '#3c4b64',
  } as React.CSSProperties;

  const editBtnStyle = {
    backgroundColor: partnerStylingOverrides?.['whitelabel.menu.buttonColor'] || primaryColor || '#fc3',
    color: partnerStylingOverrides?.['whitelabel.menu.buttonTextColor'] || primaryContrastColor || '#333',
    borderColor: partnerStylingOverrides?.['whitelabel.menu.buttonOutline'] || '#333',
    cursor: 'pointer',
  };

  const passengersCount = Number(passengers);
  const adultPassengersCount = Number(adultPassengers);
  const childPassengersCount = Number(childPassengers);
  const infantPassengersCount = Number(infantPassengers);
  const totalPassengersCount = (adultPassengersCount || passengersCount || 0) + (childPassengersCount || 0) + (infantPassengersCount || 0);
  const EditButton = ({ label, style, text }: any) => (
    <h3>
      <span>{label}</span>
      <a data-testid="button_edit-details"
      //style={style}
        className="edit-btn"
        onClick={() => {
          if (currentPathName.includes('contact-information')) {
            navigate(-1);
          } else if (currentPathName.includes('payment')) {
            navigate(-2);
          }

          setTimeout(() => {
            setShowWidget(true);
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }, 10);
        }}
      ><Pen /></a>
    </h3>
  );

  if (Object.getOwnPropertyNames(queryData).length === 0) {
    return null;
  }

  return (
    <>
      <div data-testid="booking-summary-card" className="booking-summary-card" style={bookingSummaryCustomStyles}>
        <div className="booking-summary-map d-none d-lg-block">
          {(placeId || (queryData.destination?.lat && queryData.destination?.lng)) && (
            <GeoDirection
              center={center}
              name="geographicLocation"
              queryData={{ ...queryData, placeId }}
              onOriginAndDestinationResolved={(origin: any, destination: any) => onOriginAndDestinationResolved(origin, destination)}
            />
          )}
        </div>
        <ul className="booking-summary-card_list">
          <li className="booking-summary-card_listItem">
            {(isMobile || !currentPathName.includes('vehicle-selection'))
              ? <EditButton label={t('yourTransfer')} style={editBtnStyle} text={t('edit')} />
              : <h3><span>{t('yourTransfer')}</span></h3>
            }
            <div className="line-container">
              <p className="dot" data-testid="info_address-from">{directionality?.includes('INBOUND') ? destination?.address : hubName}</p>
              <div className="date" data-testid="info_pickup-date">{directionality?.includes('INBOUND') ? formatDate(inboundPickup) : formatDate(outboundPickup)}</div>
              <div className="mt-1 mb-1">
                <div>{t('distance')}: {data?.distance}</div>
                <div data-testid="info_probable-duration">{t('probableDuration')}: {data?.duration}</div>
              </div>
            </div>
            <p className="dot" data-testid="info_address-to">{directionality?.includes('INBOUND') ? hubName : destination?.address}</p>
          </li>
          {isRoundTrip === 'true' && (<li className="booking-summary-card_listItem">
            <div className="round-trip">
              <h5 className="return"><span>{t('return')}</span></h5>
              <div className="line-container">
                <p className="dot" data-testid="info_address-to">{directionality?.includes('INBOUND') ? hubName : destination?.address}</p>
                <div className="date">{directionality?.includes('INBOUND') ? formatDate(outboundPickup) : formatDate(inboundPickup)}</div>
                <div className="mt-1 mb-1">
                  <div>{t('distance')}: {data?.returnDistance}</div>
                  <div>{t('probableDuration')}: {data?.returnDuration}</div>
                </div>
              </div>
              <p className="dot" data-testid="info_address-from">{directionality?.includes('INBOUND') ? destination?.address : hubName}</p>
            </div>
          </li>
          )}
          <li className="booking-summary-card_listItem">
            <div data-testid="info_passengers-count" className="info_passengers-count">
              <div><People /><span>{totalPassengersCount} {totalPassengersCount > 1 ? t('passengers') : t('passenger')}</span></div>
              <div><Bags /><span>{luggage} {luggage == 1 ? t('suitcase') : t('suitcases')}</span></div>
            </div>
          </li>
          {vehicleCategory && (
            <li className="booking-summary-card_listItem">
              <div className="vehicle-container">
                <div><img alt="vehicle img" className="w-100 vehicle-image" src={`/transferz/vehicles/${vehicleCategory}.jpg`} /></div>
                <div>
                  <p data-testid="info_vehicle-name">{VehicleTypeDisplayName[vehicleCategory as VehicleType]}</p>
                  {vehicleModels && <p>{`${vehicleModels.split(',')[0]} ${t('orSimilar')}`}</p>}
                  {isSharedVehicleCategory(vehicleCategory) && <SharedVehicleInformationPopup button={<div className="poniterShared"><span>{t('shared')} </span><Info /></div>} />}
                </div>
              </div>
            </li>)}
        </ul>
      </div>
      {travelAddons?.length > 0 && (<div className="booking-summary-card extras" style={bookingSummaryCustomStyles}>
        <ul className="booking-summary-card_list">
          <li className="booking-summary-card_listItem">
            <EditButton label={t('extras')} style={editBtnStyle} />
            <p data-testid="info_passengers-count">
              {travelAddons.map((item: any, index: number) => (<span key={index}> {t(`${item.type}`)}{(item.amount > 1) && ` (${item.amount})`}{(index < travelAddons.length - 1) && ','}</span>))}
            </p>
          </li>
        </ul>
      </div>)}
      {price && Array.isArray(travelAddons) && <PriceBreakdown travelAddons={travelAddons} totalPrice={price} initialPrice={initialPrice} preferredCurrencyCode={preferredCurrencyCode} pricePerPassenger={pricePerPassenger} isRoundTrip={isRoundTrip} />}
    </>
  );
};
