import './results.scss';

import { CCard, CCardBody, CCol, CRow } from '@coreui/react';
import { ISessionData } from '@transferz/models';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useLocation, useNavigate } from 'react-router-dom';

import { Header } from '../../components/header/Header';
import { useDataLayer } from '../../hooks/useDataLayer';
import { useLocalize } from '../../hooks/useLocalize';
import { useQueryBooking } from '../../queries/booking/hooks/useQueryBooking';
import { useQueryExchangeRate } from '../../queries/exchangeRates/hooks/useQueryExchangeRate';
import { useMutationPaymentResultCode } from '../../queries/payment/hooks/useMutationPaymentResultCode';
import { useQuerySessionData } from '../../queries/sessionData/hooks/useQuerySessionData';
import { useQueryTrackingData } from '../../queries/trackingData/hooks/useQueryTrackingData';
import { getQueryStringParams } from '../../utils/utils';
import { Results } from './Results';

export const PaymentResult: React.FC = () => {
  const { locale } = useLocalize();
  const { code, totalPrice, currencyCode, countryCode, id } = useParams();
  const { refetch: getTrackingData } = useQueryTrackingData({
    variables: {
      params: {
        bookingCode: '',
        hubName: '',
      },
    },
    skip: true,
  });
  const { refetch: getSessionData } = useQuerySessionData({
    variables: {
      bookingCode: '',
    },
    skip: true,
  });
  const { refetch: getBooking } = useQueryBooking({
    variables: {
      id: 1,
      bookingCode: 'a',
    },
    skip: true,
  });
  const { refetch: getExchangeRate } = useQueryExchangeRate({
    variables: {
      currencyCode: 'EUR',
      base: 'EUR',
    },
  });
  const [getResultCode] = useMutationPaymentResultCode();
  const [status, setStatus] = useState('');
  const [email, setEmail] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const urlParams: any = getQueryStringParams(location.search);
  const { trackEvent } = useDataLayer();

  const setEmailState = async () => {
    try {
      const sessionDataResult = await getSessionData({
        bookingId: Number(id),
        bookingCode: code,
      });
      setEmail(sessionDataResult.data?.sessionData?.travellerInfo?.email);
      return sessionDataResult.data?.sessionData;
    } catch (e) {
      console.log('Something went wrong with error code', e);
    }
  };
  const convertTrackingDataCurrency = (trackingData: any, rate: number) => {
    const convertedItems = trackingData?.items.map((item: any) => {
      return {
        ...item,
        price: Math.floor(item.price * rate * 100) / 100,
        priceTax: Math.floor(item.priceTax * rate * 100) / 100,
        commission: Math.floor(item.commission * rate * 100) / 100,
        currency: 'EUR',
      };
    });
    return {
      ...trackingData,
      items: convertedItems,
      value: Math.floor(trackingData.value * rate * 100) / 100,
      tax: Math.floor(trackingData.tax * rate * 100) / 100,
      commission: Math.floor(trackingData.commission * rate * 100) / 100,
      currency: 'EUR',
    };
  };

  const trackPurchase = async (sessionData: ISessionData) => {
    try {
      let exchangeRateResult;
      const trackingDataResult = await getTrackingData({
        bookingCode: code,
        hubName: sessionData?.hub?.name,
      });
      let trackingData = trackingDataResult?.data?.trackingData;
      if (trackingData?.currency !== 'EUR') {
        exchangeRateResult = await getExchangeRate({
          currencyCode: trackingData?.currency,
          base: 'EUR',
        });
        trackingData = convertTrackingDataCurrency(trackingData, exchangeRateResult?.data?.exchangeRate?.rate);
      }
      trackEvent(trackingData);
      navigate(`${location.pathname}${location.search}&doNotTrack=true`);
    } catch (e) {
      console.log('something went wrong with error code', e);
    }
  };

  useEffect(() => {
    (async () => {
      if (urlParams.redirectResult) {
        let sessionData = {} as ISessionData;
        const booking = await getBooking({ id: Number(id), bookingCode: code });

        getResultCode({
          variables: {
            id: booking?.data?.booking?.paymentRequestReference?.paymentServicePaymentRequestId,
            params: {
              details: {
                redirectResult: urlParams.redirectResult,
              },
            },
          },
        })
          .then(async ({ data }: any) => {
            const { resultCode } = data.paymentResultCode;
            trackPaymentResultCode(resultCode);
            if (resultCode !== 'Authorised' && resultCode !== 'Pending') {
              navigate(`${window.tz_globalConfigs?.customPrefixUrl || ''}/booking/${code}/payment/${code}/${id}/${totalPrice}/${currencyCode}?resultCode=${resultCode}&countryCode=${countryCode}`);
            } else {
              sessionData = await setEmailState();
            }

            /**
             * When the purchase is successful retrieve the purchase information
             * from backend and push to dataLayer.
             * To prevent double tracking of the same purchase we add a query param
             * to the URL right after the tracking is done, so that upon reload the
             * tracking does not happen.
             */
            if (resultCode === 'Authorised' && !urlParams.doNotTrack) {
              await trackPurchase(sessionData);
            }
            setStatus(resultCode);
          })
          .catch((error: any) => {
            console.log('Error while getting payment result code', error);
          });
      } else if (urlParams.status) {
        // In case of paymentMethod = card and status is successful
        setEmailState();
        trackPaymentResultCode(urlParams.status);
        setStatus(urlParams.status);
        if (urlParams.status === 'Authorised' && !urlParams.doNotTrack) {
          let sessionData = await setEmailState();
          await trackPurchase(sessionData);
        }
      }
    })();
    // eslint-disable-next-line
  }, []);

  const trackPaymentResultCode = (resultCode: string) => {
    if (!urlParams.doNotTrack) {
      trackEvent({
        event: 'payment_result_code',
        app_platform: 'web',
        page_layout: window.matchMedia('(min-width: 1241px)').matches ? 'desktop' : 'mobile',
        environment: window.tz_globalConfigs.environmentConfigs.environment,
        language: locale,
        country: urlParams.countryCode,
        website_id: window.tz_globalConfigs.name,
        partner: window.tz_globalConfigs.environmentConfigs.environment === 'local' ? 'localhost:3030' : window.location.host.split('.')[0],
        resultCode,
      });
    }
  };

  return (
    <CCard>
      <div className="container-lg">
        <CRow>
          {
            status
            && <CCol>
              <div className="d-flex justify-content-sm-between align-items-center mb-4">
                <a href={`${window.tz_globalConfigs?.customPrefixUrl || '/'}`}>
                  <img className="logo" src={window.tz_globalConfigs?.styling?.logoUrl} alt="" />
                </a>
                <Header showCurrency={false} />
              </div>
              <CCardBody>
                <Results status={status} email={email} />
              </CCardBody>
            </CCol>
          }
        </CRow>
      </div>
    </CCard>
  );
};

export default PaymentResult;
