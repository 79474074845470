import './sidebar.scss';

import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useSessionStorage } from '../../hooks/sessionStorage/useSessionStorage';
import { useBookingContext } from '../../hooks/useBookingContext';
import { useLocalize } from '../../hooks/useLocalize';
import { useQueryGetRoadDistance } from '../../queries/geo/hooks/useQueryGetRoadDistance';
import { getQueryStringParams } from '../../utils/utils';
import { formatTimeInterval } from '../../utils/utils';
import { BookingSummary } from '../bookingSummary/bookingSummary';

export const Sidebar: React.FC<any> = ({ sessionData }: any) => {
  const { locale } = useLocalize();
  const [transferData, setTransferData] = useState({}) as any;
  const [queryData, setQueryData] = useState({}) as any;
  const location = useLocation();
  const { preferredCurrencyCode } = useBookingContext();
  const [, setEstimatedDurationInSession] = useSessionStorage('estimatedDuration');
  const { refetch: refetchRoadDistance } = useQueryGetRoadDistance({
    skip: true,
    variables: {},
  });

  const { logoUrl } = window.tz_globalConfigs.styling;
  const { partnerStylingOverrides } = window.tz_globalConfigs;
  const urlParams: any = getQueryStringParams(window.location.search);

  const onOriginAndDestinationResolved = (origin: any, destination: any) => {
    const promises = [
      refetchRoadDistance({
        origin,
        destination,
        measurementSystem: 'METRIC',
      }),
    ];

    if (queryData.isRoundTrip === 'true') {
      promises.push(
        refetchRoadDistance({
          origin: destination,
          destination: origin,
          measurementSystem: 'METRIC',
        }),
      );
    }

    Promise.all(promises)
      .then(([response, roundTripResponse]: any) => {
        const duration = formatTimeInterval(response.data.roadDistance?.durationInSeconds, locale);
        const distance = `${Math.round((response.data.roadDistance?.distance + Number.EPSILON) * 10) / 10} km`;

        transferData.distance = distance;
        transferData.duration = duration;

        if (queryData.isRoundTrip === 'true') {
          const returnDuration = `${Math.round(roundTripResponse.data.roadDistance?.durationInSeconds / 60)} min`;
          const returnDistance = `${Math.round((roundTripResponse.data.roadDistance?.distance + Number.EPSILON) * 10) / 10} km`;
          transferData.returnDistance = returnDistance;
          transferData.returnDuration = returnDuration;
        }

        setTransferData(transferData);
        setEstimatedDurationInSession(duration);
      });
  };

  const getQueryData = () => {
    const destinationObj = urlParams.destination && JSON.parse(urlParams.destination);
    const queryData: any = {
      partnerId: window.tz_globalConfigs?.partnerId,
      placeId: (urlParams.placeId !== 'undefined' && urlParams.placeId !== 'null') ? urlParams.placeId : null,
      hubId: urlParams.hubId,
      hubType: urlParams.hubType,
      hubName: urlParams.hubName,
      hubNameSecondary: urlParams.hubNameSecondary,
      vehicleCategory: urlParams.vehicleCategory,
      vehicleModels: urlParams.vehicleModels,
      directionality: urlParams.directionality,
      price: urlParams.totalPrice ? urlParams.totalPrice : urlParams.price,
      initialPrice: urlParams.initialPrice,
      pricePerPassenger: urlParams.pricePerPassenger,
      travelAddons: urlParams.travelAddons ? JSON.parse(urlParams.travelAddons) : null,
      destination: destinationObj,
      ...(!urlParams.adultPassengers ? { passengers: Number(urlParams.passengers) } : { adultPassengers: Number(urlParams.adultPassengers) }),
      ...(urlParams.childPassengers && { childPassengers: Number(urlParams.childPassengers) }),
      ...(urlParams.infantPassengers && { infantPassengers: Number(urlParams.infantPassengers) }),
      luggage: Number(urlParams.luggage),
      inboundPickup: urlParams.inboundPickup,
      outboundPickup: urlParams.outboundPickup,
      hubLat: Number(urlParams?.hubLat),
      hubLng: Number(urlParams?.hubLng),
      isRoundTrip: urlParams.isRoundTrip,
      preferredCurrencyCode: preferredCurrencyCode,
    };
    return queryData;
  };

  useEffect(() => {
    const queryData = getQueryData();
    setTransferData(transferData);
    setQueryData(queryData);
    // eslint-disable-next-line
  }, [location]);

  useEffect(() => {
    if (Object.getOwnPropertyNames(sessionData).length) {
      setQueryData(sessionData);
    }
    // eslint-disable-next-line
  }, [sessionData]);

  const center = (transferData?.hubLat && transferData?.hubLng)
    ? {
      lat: transferData?.hubLat,
      lng: transferData?.hubLng,
    }
    : undefined;

  return (
    <aside className="sidebar">
      <a href={`${window.tz_globalConfigs?.customPrefixUrl || ''}${urlParams.initialUrl || '/'}`}>
        <img className="logo-image" src={partnerStylingOverrides?.['whitelabel.logoUrl'] || logoUrl} alt="" />
      </a>
      <BookingSummary data={transferData} queryData={queryData} center={center} onOriginAndDestinationResolved={(origin: any, destination: any) => onOriginAndDestinationResolved(origin, destination)} />
    </aside>
  );
};
