import './vehicleSelection.scss';

import { CCol, CRow } from '@coreui/react';
import { Loading } from '@transferz/components/loading';
import { TravelAddonType, VehicleType } from '@transferz/models';
import { getCurrencyInfo } from '@transferz/utils';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'usehooks-ts';

import { ReactComponent as ArrowRight } from '../../assets/svgs/arrowRight.svg';
import { ReactComponent as Baby } from '../../assets/svgs/baby.svg';
import { ReactComponent as Bags } from '../../assets/svgs/bags.svg';
import { ReactComponent as Booster } from '../../assets/svgs/booster.svg';
import { ReactComponent as ChevronDown } from '../../assets/svgs/chevronDown.svg';
import { ReactComponent as ChevronUp } from '../../assets/svgs/chevronUp.svg';
import { ReactComponent as EcoIcon } from '../../assets/svgs/ecoIcon.svg';
import { ReactComponent as ExtraStop } from '../../assets/svgs/extraStop.svg';
import { ReactComponent as Shared } from '../../assets/svgs/groupShared.svg';
import { ReactComponent as Info } from '../../assets/svgs/info-grey.svg';
import { ReactComponent as MeetGreet } from '../../assets/svgs/meetGreet.svg';
import { ReactComponent as People } from '../../assets/svgs/people.svg';
import { ReactComponent as Pets } from '../../assets/svgs/pets.svg';
import { ReactComponent as PinAlt } from '../../assets/svgs/pin-alt.svg';
import { ReactComponent as SpecialLuggage } from '../../assets/svgs/specialLuggage.svg';
import { ReactComponent as Clock } from '../../assets/svgs/time.svg';
import { JourneyInfoCondensed } from '../../components/journeyInfoCondensed/JourneyInfoCondensed';
import { UniqueSellingPoints } from '../../components/ups/UniqueSellingPoints';
import SharedVehicleInformationPopup from '../../components/vehicleCategoryInformationPopup/SharedVehicleInformationPopup';
import { Widget } from '../../components/widget/Widget';
import { useBookingContext } from '../../hooks/useBookingContext';
import { useDataLayer } from '../../hooks/useDataLayer';
import { useLocalize } from '../../hooks/useLocalize';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { getQueryStringParams } from '../../utils/utils';
import AddonContainer from './AddonContainer';

export const isSharedVehicleCategory = (vehicleCategory: any) => {
  return (vehicleCategory === VehicleType.Shuttle || vehicleCategory === VehicleType.SpeedyShuttle || vehicleCategory === VehicleType.DirectShuttle);
};

/*
 * An interface for the url params used in this component
 * with jsdocs for each param
 */
interface UrlParams {

  /**
   * A boolean value that indicates if the trip is round trip
   * @example true
   * @example false
   *
   * @type {string}
   */
  isRoundTrip: string;

  /**
   * The preferred currency code
   * @example EUR
   *
   * @type {string}
   */
  preferredCurrencyCode: string;

  /**
   * The total number of passengers. It is the sum of adult, child and infant passengers.
   * @example 4
   *
   * @type {string}
   */
  passengers: string;

  /**
   * The number of adult passengers. If this parameter is used,
   * @example 2
   *
   * @type {string}
   */
  adultPassengers: string;

  /**
   * The number of child passengers
   * @example 1
   *
   * @type {string}
   */
  childPassengers: string;

  /**
   * The number of infant passengers
   * @example 1
   *
   * @type {string}
   */
  infantPassengers: string;

  /**
   * The total number of luggage. It is the sum of all luggages.
   * @example 2
   *
   * @type {string}
   */
  luggage: string;

  /**
   * The number of carry-on luggage
   * @example 1
   *
   * @type {string}
   */
  carryOnLuggage: string;

  /**
   * The number of checked luggage
   * @example 1
   *
   * @type {string}
   */
  checkedLuggage: string;

  /**
   * The hub name.
   * @example Amsterdam Schipol Airport
   *
   * @type {string}
   */
  hubName: string;

  /**
   * The destination object. It is Json stringified.
   * We will use the first address resolved by Google Maps.
   * @example {address: 'Museumplein, Amsterdam, Netherlands'}
   */
  destination: string;

  /**
   * The directionality of the trip. It can be either OUTBOUND or INBOUND.
   * @example OUTBOUND
   *
   * @type {'OUTBOUND' | 'INBOUND' | 'ROUNDTRIP_OUTBOUND' | 'ROUNDTRIP_INBOUND'}
   */
  directionality: string;
}

export const VehicleSelection: React.FC<any> = () => {
  const { translate: t } = useLocalize();
  const flags = useFlags();
  const { trackEvent } = useDataLayer();
  const navigate = useNavigate();
  const location = useLocation();
  const [preferredLanguage] = useLocalStorage('preferredLanguage');
  const [showAvailableOptions, setShowAvailableOptions] = useState({} as any);
  const [selectedTravelAddon, setSelectedTravelAddon] = useState({} as any);
  const [possibleSelectionAmount, setPossibleSelectionAmount] = useState({} as any);
  const [selectedQuote, setSelectedQuote] = useState({} as any);
  const [selectedAddon, setSelectedAddon] = useState({} as any);
  const { quotes, isLoading, error, retrieveQuotes, preferredCurrencyCode, setPreferredCurrencyCode, setShowWidget, showWidget } = useBookingContext();
  const { partnerStylingOverrides } = window.tz_globalConfigs;

  const urlParams = useMemo<UrlParams>(() => getQueryStringParams(window.location.search), [window.location.search]);
  const hubName = urlParams.hubName;
  const destination = JSON.parse(urlParams.destination);
  const isOutbound = !urlParams.directionality?.includes('INBOUND');

  const isMobile = useMediaQuery('(max-width: 576px)');

  useEffect(() => {
    let preselectedAddons = {};
    quotes && quotes.forEach((quote: any) => {

      quote.travelAddons.forEach((addon: any) => {
        if (addon.type === TravelAddonType.MeetGreet && addon.fare === 0) {
          preselectedAddons = {
            ...preselectedAddons,
            [quote.ids[0]]: {

              [addon.quoteTravelAddonIdPairs[quote.ids[0]]]: 1,
            },
          };
        }
      });
    });

    setSelectedTravelAddon(preselectedAddons);
  }, [quotes]);

  useEffect(() => {
    const fetchRetrieveQuotes = async () => {
      const quotes = await retrieveQuotes(urlParams.preferredCurrencyCode || (flags?.dataPreselected ? preferredCurrencyCode : ''));
      if (quotes?.quotes[0]?.currencyCode && !urlParams.preferredCurrencyCode) {
        setPreferredCurrencyCode(quotes?.quotes[0]?.currencyCode);
      }
    };

    trackWidgetEvent();
    fetchRetrieveQuotes();
  }, [urlParams]);

  const trackWidgetEvent = () => {
    try {
      const urlParams: any = new URLSearchParams(window.location.search);
      const data = urlParams.get('widgetTrackData');
      const trackData = JSON.parse(data);
      trackEvent(trackData);
    } catch (e) {
      console.log('Error while parsing widget track data', e);
    }
  };

  const renderIcon = (travelAddon: any) => {
    switch (travelAddon.type) {
    case TravelAddonType.MeetGreet:
      return (<MeetGreet className="option-icon"/>);
    case TravelAddonType.BabySeat:
      return (<Baby className="option-icon"/>);
    case TravelAddonType.BoosterSeat:
      return (<Booster className="option-icon"/>);
    case TravelAddonType.Pets:
      return (<Pets className="option-icon"/>);
    case TravelAddonType.ExtraStop:
      return (<ExtraStop className="option-icon" />);
    case TravelAddonType.SpecialLuggage:
      return (<SpecialLuggage className="option-icon"/>);
    default:
      return (<></>);
    }
  };

  const getTravelAddonsWithType = (quote: any, travelAddons: any) => {
    const quoteTravelAddons = quote.travelAddons;
    const addonPair = {} as any;
    const travelAddonsWithType: any = [];

    travelAddons[quote.ids[0]].forEach((addon: any) => addonPair[addon.quoteTravelAddonId] = addon.amount);

    quoteTravelAddons.forEach((addon: any) => {
      if (Object.prototype.hasOwnProperty.call(addonPair, addon.quoteTravelAddonIdPairs[quote.ids[0]])) {
        travelAddonsWithType.push({
          type: addon.type,
          amount: addonPair[addon.quoteTravelAddonIdPairs[quote.ids[0]]],
          price: addon.price,
        });
      }
    });

    return travelAddonsWithType;
  };

  const selectVehicle = (quote: any) => {
    const quotes: Array<any> = [];
    const travelAddons: { [key: string]: Array<{ quoteTravelAddonId: string, amount: string }> } = {};

    travelAddons[quote.ids[0]] = [];

    if (selectedTravelAddon[quote.ids[0]]) {
      for (let key in selectedTravelAddon[quote.ids[0]]) {
        travelAddons[quote.ids[0]].push({
          quoteTravelAddonId: key,
          amount: selectedTravelAddon[quote.ids[0]][key],
        });
      }
    }

    quotes.push({
      quoteId: quote.ids[0],
      quoteTravelAddons: travelAddons[quote.ids[0]],
    });

    if (quote.ids.length === 2) {
      travelAddons[quote.ids[1]] = [];

      for (let key in travelAddons[quote.ids[0]]) {
        quote.travelAddons.forEach((addon: any) => {
          if (+addon.quoteTravelAddonIdPairs[quote.ids[0]] === +travelAddons[quote.ids[0]][key].quoteTravelAddonId) {
            travelAddons[quote.ids[1]].push({
              quoteTravelAddonId: addon.quoteTravelAddonIdPairs[quote.ids[1]],
              amount: travelAddons[quote.ids[0]][key].amount,
            });
          }
        });
      }

      quotes.push({
        quoteId: quote.ids[1],
        quoteTravelAddons: travelAddons[quote.ids[1]],
      });
    }

    const travelAddonsWithType = getTravelAddonsWithType(quote, travelAddons);
    const travelAddonPrice = travelAddonsWithType.reduce((total: any, addon: any) => total + (addon.amount * addon.price.price), 0);
    const totalPrice = quote.price + (quote.ids.length * travelAddonPrice);
    const selections = `vehicleCategory=${quote.vehicleCategory}&passengerCapacity=${quote.passengerCapacity}&luggageCapacity=${quote.luggageCapacity}&price=${getCurrencyInfo(quote.currencyCode).symbol} ${totalPrice.toFixed(2)}&initialPrice=${getCurrencyInfo(quote.currencyCode).symbol} ${quote.price.toFixed(2)}&pricePerPassenger=${quote.pricePerPassenger?.toFixed(2)}&travelAddons=${JSON.stringify(travelAddonsWithType)}&vehicleModels=${quote.vehicleModels}`;
    const newQueryParams = `&quotes=${JSON.stringify(quotes)}&${selections}`;
    const trackData = getTrackEventData(quote, travelAddonsWithType);
    trackEvent(trackData);
    navigate(`${window.tz_globalConfigs?.customPrefixUrl || ''}/booking/contact-information${location.search}${newQueryParams}`);
  };

  const getTrackEventData = (quote: any, addons: any) => {
    const vehicleType = quote.vehicleCategory;
    const price = quote.price;
    let babySeats = '';
    let boosterSeats = '';
    let specialLuggage = '';
    let pets = '';
    let meetGreet = '';
    let currency = quote.currencyCode;
    addons.forEach(({ type, amount }: any) => {
      switch (type) {
      case 'BABY_SEAT':
        babySeats = amount;
        break;
      case 'BOOSTER_SEAT':
        boosterSeats = amount;
        break;
      case 'SPECIAL_LUGGAGE':
        specialLuggage = amount;
        break;
      case 'PETS':
        pets = amount;
        break;
      case 'MEET_GREET':
        meetGreet = 'yes';
        break;
      }
    });
    return {
      event: 'checkout_vehicle',
      app_platform: 'web',
      page_layout: window.matchMedia('(min-width: 1241px)').matches ? 'desktop' : 'mobile',
      environment: window.tz_globalConfigs?.environmentConfigs.environment,
      language: preferredLanguage || 'nl-NL',
      website_id: window.tz_globalConfigs?.name,
      checkout_vehicle_type: vehicleType,
      checkout_price: price,
      checkout_baby_seats: babySeats,
      checkout_booster_seats: boosterSeats,
      checkout_special_luggage: specialLuggage,
      checkout_pets: pets,
      checkout_meet_greet: meetGreet,
      currency: currency,
    };
  };

  const showPossibleSelectionAmount = (quote: any, addon: any) => {
    const maxAllowed = addon.maxAllowed;
    const isBabyOrBoosterAddon = addon.type === TravelAddonType.BabySeat || addon.type === TravelAddonType.BoosterSeat;

    if (isBabyOrBoosterAddon) {

      /**
       *  The business logic of this part.
       *  Besides the allowed amount on each add-on for baby and booster seats we have a combined number,
       *  which means the user can select baby/booster seats but the sum of those amounts should not be more than a combined number.
       *  That’s why we are hiding the numbers which  a user can’t select anymore.
       */

      const babySeat = quote.travelAddons.filter((addon: any) => addon.type === TravelAddonType.BabySeat)[0];
      const boosterSeat = quote.travelAddons.filter((addon: any) => addon.type === TravelAddonType.BoosterSeat)[0];
      const babySeatId = babySeat?.quoteTravelAddonIdPairs?.[quote.ids[0]];
      const boosterSeatId = boosterSeat?.quoteTravelAddonIdPairs?.[quote.ids[0]];
      let selectedBabySeatAmount = selectedTravelAddon[quote.ids[0]]?.[babySeatId];
      let selectedBoosterSeatAmount = selectedTravelAddon[quote.ids[0]]?.[boosterSeatId];
      let maxAllowedBabySeat;
      let maxAllowedBoosterSeat;

      if (!selectedBoosterSeatAmount) {
        maxAllowedBabySeat = babySeat?.maxAllowed;
      } else {
        const allowedAmount = quote.combinedBabyAndBoosterSeatsCapacity - selectedBoosterSeatAmount;
        maxAllowedBabySeat = babySeat?.maxAllowed < allowedAmount ? babySeat?.maxAllowed : allowedAmount;
      }
      if (!selectedBabySeatAmount) {
        maxAllowedBoosterSeat = boosterSeat?.maxAllowed;
      } else {
        const allowedAmount = quote.combinedBabyAndBoosterSeatsCapacity - selectedBabySeatAmount;
        maxAllowedBoosterSeat = boosterSeat?.maxAllowed < allowedAmount ? boosterSeat?.maxAllowed : allowedAmount;
      }

      setPossibleSelectionAmount({
        ...possibleSelectionAmount,
        [quote?.ids?.[0]]: {
          ...possibleSelectionAmount[quote.ids[0]],
          [TravelAddonType.BabySeat]: {
            maxAllowed: [...Array(maxAllowedBabySeat)],
          },
          [TravelAddonType.BoosterSeat]: {
            maxAllowed: [...Array(maxAllowedBoosterSeat)],
          },
        },
      });
    } else {
      setPossibleSelectionAmount({
        ...possibleSelectionAmount,
        [quote?.ids?.[0]]: {
          ...possibleSelectionAmount[quote.ids?.[0]],
          [addon.type]: {
            maxAllowed: maxAllowed ? [...Array(maxAllowed)] : [],
          },
        },
      });
    }
  };

  const selectAddonAmount = (quote: any, addon: any, amount: number) => {
    setSelectedAddon(addon);
    setSelectedQuote(quote);

    setSelectedTravelAddon({
      ...selectedTravelAddon,
      [quote.ids[0]]: {
        ...selectedTravelAddon[quote.ids[0]],
        [addon.quoteTravelAddonIdPairs[quote.ids?.[0]]]: amount,
      },
    });
  };

  const unSelectAddonAmount = (quote: any, addon: any) => {
    const selectedTravelAddonCopy = JSON.parse(JSON.stringify(selectedTravelAddon));
    setSelectedAddon(addon);
    setSelectedQuote(quote);

    selectedTravelAddonCopy[quote.ids[0]] && delete selectedTravelAddonCopy[quote.ids[0]][addon.quoteTravelAddonIdPairs[quote.ids[0]]];
    setSelectedTravelAddon({
      ...selectedTravelAddon,
      ...selectedTravelAddonCopy,
    });
  };

  const selectAddon = (e: any, quote: any, addon: any) => {
    const isBabyOrBoosterAddon = addon.type === TravelAddonType.BabySeat || addon.type === TravelAddonType.BoosterSeat;

    showPossibleSelectionAmount(quote, addon);
    setSelectedAddon(addon);
    setSelectedQuote(quote);

    if (e.currentTarget.checked) {
      // if there is only one available amount select it by default, if there is more than one options user should select it explicitly
      if (addon.maxAllowed === 1 && !isBabyOrBoosterAddon) {
        selectAddonAmount(quote, addon, 1);
      }
    } else {
      e.currentTarget.parentElement.querySelectorAll('input[type="radio"]').forEach((element: HTMLInputElement) => element.checked = false);
      unSelectAddonAmount(quote, addon);
    }
  };

  const formatPrice = (price: number) => {
    return price % 1 !== 0 ? price.toFixed(2) : price;
  };

  const addPercentage = (price: number, percentage: number) => {
    return (100 * price) / (100 - percentage);
  };

  useEffect(() => {
    // in case of baby and booster seat it should recalculate the possible selection amount and show them
    if (Object.entries(selectedTravelAddon).length) {
      showPossibleSelectionAmount(selectedQuote, selectedAddon);
    }
    // eslint-disable-next-line
  }, [selectedTravelAddon]);

  const customVehicleSelectionStyles = {
    '--whitelabel-addons-text-color': window.tz_globalConfigs?.partnerStylingOverrides?.['whitelabel.addons.textColor'],
  } as React.CSSProperties;

  return (
    <>
      {isMobile && !showWidget ? (
        <JourneyInfoCondensed
          roundtrip={urlParams.isRoundTrip === 'true'}
          onClick={() => setShowWidget(true)}
          origin={isOutbound ? hubName : destination.address}
          destination={isOutbound ? destination.address : hubName}
        />
      ) : (
        <div className="vehicle-selection-widget-container">
          <Widget showTextOnTop={false} variant={isMobile ? 'default' : 'condensed'} usePartnerStyles={isMobile}/>
        </div>
      )}
      <CCol md="12" className="vehicles pl-0 pr-0">
        {!!quotes?.length && <><h3 className="page-title title-black">{t('content.vehicle')}</h3><UniqueSellingPoints /></>}
        {isLoading && (
          <div className="loading-component">
            <div className="loading-animation" style={{ color: window.tz_globalConfigs?.styling?.backgroundContrastColor || '#fc3' }}>
              <Loading />
            </div>
            <div>{t('checkingOptions')}</div>
          </div>
        )}
        {!isLoading && !!quotes?.length && quotes.map((quote: any, index: number) => {
          const addonsAlphabeticallySorted = quote.travelAddons.sort((a: any, b: any) => a.type.localeCompare(b.type));
          const meetGreetAddonIndex = addonsAlphabeticallySorted.findIndex((addon: any) => addon.type === TravelAddonType.MeetGreet);
          const meetGreetAddonFree = !!addonsAlphabeticallySorted.find((addon: any) => addon.type === TravelAddonType.MeetGreet && addon.fare === 0);

          return (
            <div key={index}>
              {isMobile
                ? <CCol className="vehicles-border vehicle-card mobile" style={customVehicleSelectionStyles}>
                  <CRow className="align-items-center">
                    <img alt="vehicle img" className="w-100 vehicle-image" src={`/transferz/vehicles/${quote.vehicleCategory}.jpg`} />
                    <CCol>
                      <div>
                        {quote.vehicleCategory.includes('ELECTRIC')
                          ? <h4 className="eco-title vehicle-title">{t(quote.vehicleCategory)} <EcoIcon /></h4>
                          : <h4 className="vehicle-title" style={{ color: partnerStylingOverrides?.['whitelabel.vehicleCategory.textColor'] }}>{t(quote.vehicleCategory)}</h4>
                        }
                      </div>
                      <div className="main-price" style={{ color: partnerStylingOverrides?.['whitelabel.prices.textColor'] }}>
                        {getCurrencyInfo(quote.currencyCode).symbol}&nbsp;{formatPrice(quote.price)}
                      </div>
                      {isSharedVehicleCategory(quote.vehicleCategory) && (
                        <div className="price-per-person mt-2">
                          {`${getCurrencyInfo(quote.currencyCode).symbol} ${formatPrice(quote.pricePerPassenger)} / ${t('person')}`}
                        </div>
                      )}
                      <CRow className="discount-price-container-mobile ml-0 mr-0 mt-1">
                        {quote.discount > 0
                          && <span className="price-old">{getCurrencyInfo(quote.currencyCode).symbol} {formatPrice(addPercentage(quote.price, quote.discount))}</span>
                        }
                        {quote.discount > 0
                          && <div className="discount-message">&nbsp;{`${t('youSave')} ${quote.discount}%`}</div>
                        }
                      </CRow>
                    </CCol>
                  </CRow>
                  {
                    quote.vehicleModels && <div className="vehicle-model w-100 justify-content-start mt-2">
                      {`${quote.vehicleModels.split(',')[0]} ${t('orSimilar')}`}
                    </div>
                  }
                  <div className="w-100 justify-content-start row m-1 mr-0 mt-3 pb-1">
                    {isSharedVehicleCategory(quote.vehicleCategory) ? '' : <div data-testid="info_passenger-count" className="quantity pr-3"><People /> <span className="max-people" style={{ color: partnerStylingOverrides?.['whitelabel.addons.textColor'] }}>{`${t('max')} ${quote.passengerCapacity}`}</span> </div>}
                    <div data-testid="info_luggage-count" className="quantity pl-0 pr-0"><Bags /><span style={{ color: partnerStylingOverrides?.['whitelabel.addons.textColor'] }}>{isSharedVehicleCategory(quote.vehicleCategory) ? `${t('MaxPerPerson')}` : `${t('max')} ${quote.luggageCapacity}`} </span></div>
                  </div>
                  <div className="w-100 justify-content-start row m-1 mr-0 pb-1">
                    <div data-testid="info_waiting-time" className="quantity">
                      <Clock />
                      <span style={{ color: partnerStylingOverrides?.['whitelabel.addons.textColor'] }}>
                        {isSharedVehicleCategory(quote.vehicleCategory) ? t(`usp_main_1_${quote.vehicleCategory.toLowerCase()}`) : t('usp_main_1_private', { includedWaitingTimeInMinutes: quote?.includedWaitingTimeInMinutes })}
                      </span>
                    </div>
                  </div>
                  {isSharedVehicleCategory(quote.vehicleCategory) && <div className="w-100 justify-content-start row m-1 mr-0 pb-1">
                    <div data-testid="info_estimated-duration" className="quantity"><PinAlt /><span style={{ color: partnerStylingOverrides?.['whitelabel.addons.textColor'] }}>{t(`usp_main_2_${quote.vehicleCategory.toLowerCase()}`)} </span></div>
                  </div>}
                  {isSharedVehicleCategory(quote.vehicleCategory) && <div className="w-100 justify-content-start row m-1 mr-0 pb-1">
                    <div data-testid="info_estimated-duration" className="quantity"><SharedVehicleInformationPopup button={<div><Shared /><span>{t('shared')} </span><Info /></div>} /></div>
                  </div>}
                  {meetGreetAddonFree && <div className="w-100 justify-content-start row m-1 mr-0 pb-1">
                    <div data-testid="info_meet_greet_included" className="quantity"><MeetGreet /><span>{t('FreeMeetGreetIncluded')}</span></div>
                  </div>}

                  {addonsAlphabeticallySorted.length
                    ? !showAvailableOptions[quote.ids[0]] && (
                      <button
                        className="button-secondary"
                        onClick={() => setShowAvailableOptions({
                          ...showAvailableOptions,
                          [quote.ids[0]]: true,
                        })}
                        data-testid="button_add-extras"
                      >
                        <span>{t('addExtras')}</span>
                        <ChevronDown />
                      </button>
                    )
                    : null
                  }
                  {addonsAlphabeticallySorted.length
                    ? showAvailableOptions[quote.ids[0]] && (
                      <div
                        className="button-tertiary"
                        onClick={() => setShowAvailableOptions({
                          ...showAvailableOptions,
                          [quote.ids[0]]: false,
                        })}
                        data-testid="button_add-extras--expanded"
                      >
                        <span>{t('addExtras')}</span>
                        <ChevronUp />
                      </div>
                    )
                    : null}
                  <CRow className={`vehicles-list mr-0 ml-0 ${showAvailableOptions[quote.ids[0]] ? 'vehicles-list-expanded' : ''}`}>
                    <div className={`booking-options w-100 ${showAvailableOptions[quote.ids[0]] ? 'show-options' : ''}`} >
                      <ul className="pl-0">
                        <li className="option">
                          <AddonContainer
                            travelAddon={addonsAlphabeticallySorted[meetGreetAddonIndex]}
                            quote={quote}
                            possibleSelectionAmount={possibleSelectionAmount}
                            selectAddonAmount={selectAddonAmount}
                            selectAddon={selectAddon} />
                        </li>
                        {addonsAlphabeticallySorted.map((travelAddon: any, index: number) => {
                          if (index === meetGreetAddonIndex) {
                            return;
                          }
                          return (
                            <li className="option" key={index} style={{ color: partnerStylingOverrides?.['whitelabel.addons.textColor'] }}>
                              <AddonContainer
                                travelAddon={travelAddon}
                                quote={quote}
                                possibleSelectionAmount={possibleSelectionAmount}
                                selectAddonAmount={selectAddonAmount}
                                selectAddon={selectAddon} />
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </CRow>

                  <div className="vehicle-select">
                    <button data-testid="button_select-vehicle" className="button-confirm" style={{ backgroundColor: partnerStylingOverrides?.['whitelabel.widget.buttonColor'] || window.tz_globalConfigs?.styling?.secondaryColor || '#48a947', color: partnerStylingOverrides?.['whitelabel.widget.buttonTextColor'] || window.tz_globalConfigs?.styling?.secondaryContrastColor || '#fff' }} onClick={() => selectVehicle(quote)} >
                      <span>{t('select')}</span>
                      <ArrowRight />
                    </button>
                  </div>
                </CCol>
                : <div className="vehicles-border vehicle-card" style={customVehicleSelectionStyles}>
                  <CRow className="mr-0 ml-0 row-item">
                    <CCol className="text-center main-side">
                      <img alt="vehicle img" className="w-100 vehicle-image" src={`/transferz/vehicles/${quote.vehicleCategory}.jpg`} />
                    </CCol>
                    <CCol className="main-center">
                      <CRow>
                        <CCol sm="12">
                          <div className="justify-content-between row align-items-top vehicle-content">
                            <div>
                              {quote.vehicleCategory.includes('ELECTRIC')
                                ? <h4 className="eco-title pt-2 vehicle-title">{t(quote.vehicleCategory)} <EcoIcon /></h4>
                                : <h4 className="pt-2 vehicle-title">{t(quote.vehicleCategory)}</h4>
                              }
                              {!isSharedVehicleCategory(quote.vehicleCategory) && !!quote.vehicleModels
                                && <div className="vehicle-model w-100 justify-content-start mt-2">
                                  {`${quote.vehicleModels.split(',').slice(0, 3)
                                    .join(',')} ${t('orSimilar')}`}</div>
                              }
                              <div className="w-100 justify-content-start row ml-0 mr-0 mt-3 mb-1 pb-1 vehicle-usp-info">
                                {isSharedVehicleCategory(quote.vehicleCategory) ? '' : <div data-testid="info_passenger-count" className="quantity pr-1"><People /> <span className="max-people" style={{ color: partnerStylingOverrides?.['whitelabel.addons.textColor'] }}>{`${t('max')} ${quote.passengerCapacity}`}</span> </div>}
                                <div data-testid="info_luggage-count" className="quantity pl-0 pr-0"><Bags/><span style={{ color: partnerStylingOverrides?.['whitelabel.addons.textColor'] }}>{isSharedVehicleCategory(quote.vehicleCategory) ? `${t('MaxPerPerson')}` : `${t('max')} ${quote.luggageCapacity}`} </span></div>
                              </div>
                              <div className="w-100 justify-content-start row ml-0 mr-0 mt-1 mb-1 pb-1 vehicle-usp-info">
                                <div data-testid="info_waiting-time" className="quantity">
                                  <Clock />
                                  {!isSharedVehicleCategory(quote.vehicleCategory) && <>&nbsp;</>}
                                  <span>
                                    {isSharedVehicleCategory(quote.vehicleCategory) ? t(`usp_main_1_${quote.vehicleCategory.toLowerCase()}`) : t('usp_main_1_private', { includedWaitingTimeInMinutes: quote?.includedWaitingTimeInMinutes })}
                                  </span>
                                </div>
                              </div>
                              {isSharedVehicleCategory(quote.vehicleCategory) && <div className="w-100 justify-content-start row ml-0 mr-0 mt-1 mb-1 pb-1">
                                <div data-testid="info_estimated-duration" className="quantity"><PinAlt /><span style={{ color: partnerStylingOverrides?.['whitelabel.addons.textColor'] }}>{t(`usp_main_2_${quote.vehicleCategory.toLowerCase()}`)} </span></div>
                              </div>}
                              {isSharedVehicleCategory(quote.vehicleCategory) && <div className="w-100 justify-content-start row m-1 mr-0 pb-1">
                                <div data-testid="info_estimated-duration" className="quantity pointer"><SharedVehicleInformationPopup button={<div><Shared /><span>{t('shared')} </span><Info /></div>} /></div>
                              </div>}
                              {meetGreetAddonFree && <div className="w-100 justify-content-start row ml-0 mr-0 mt-1 mb-1 pb-1">
                                <div data-testid="info_meet_greet_included" className="quantity"><MeetGreet /><span>{t('FreeMeetGreetIncluded')}</span></div>
                              </div>}
                            </div>
                            <div className="booking-costs ml-4">
                              <span className="font-4xl dark-color font-bold main-price" style={{ color: partnerStylingOverrides?.['whitelabel.prices.textColor'] }}>
                                {getCurrencyInfo(quote.currencyCode).symbol}&nbsp;{formatPrice(quote.price)}
                              </span>
                              {isSharedVehicleCategory(quote.vehicleCategory) && (
                                <div className="price-per-person">
                                  {`${getCurrencyInfo(quote.currencyCode).symbol} ${formatPrice(quote.pricePerPassenger)} / ${t('person')}`}
                                </div>
                              )}
                              {quote.discount > 0
                              && <span><span className="price-old">{getCurrencyInfo(quote.currencyCode).symbol} {formatPrice(addPercentage(quote.price, quote.discount))}</span> <span className="discount-message pb-2">{`${t('youSave')} ${quote.discount}%`}</span></span>
                              }
                              <div className="vehicle-select">
                                <button data-testid="button_select-vehicle" className="button-confirm" style={{ backgroundColor: partnerStylingOverrides?.['whitelabel.widget.buttonColor'] || window.tz_globalConfigs?.styling?.secondaryColor || '#48a947', color: partnerStylingOverrides?.['whitelabel.widget.buttonTextColor'] || window.tz_globalConfigs?.styling?.secondaryContrastColor || '#fff' }} onClick={() => selectVehicle(quote)} >
                                  <span>{t('select')}</span>
                                  <ArrowRight />
                                </button>
                              </div>
                              {addonsAlphabeticallySorted.length
                                ? !showAvailableOptions[quote.ids[0]] && (
                                  <button
                                    className="button-secondary"
                                    onClick={() => setShowAvailableOptions({
                                      ...showAvailableOptions,
                                      [quote.ids[0]]: true,
                                    })}
                                    data-testid="button_add-extras"
                                  >
                                    <span>{t('addExtras')}</span>
                                    <ChevronDown />
                                  </button>
                                )
                                : null
                              }
                              {addonsAlphabeticallySorted.length
                                ? showAvailableOptions[quote.ids[0]] && (
                                  <div
                                    className="button-tertiary"
                                    onClick={() => setShowAvailableOptions({
                                      ...showAvailableOptions,
                                      [quote.ids[0]]: false,
                                    })}
                                    data-testid="button_add-extras--expanded"
                                  >
                                    <span>{t('addExtras')}</span>
                                    <ChevronUp />
                                  </div>
                                )
                                : null}
                            </div>
                          </div>
                        </CCol>
                      </CRow>
                    </CCol>
                  </CRow>
                  <CRow className={`vehicles-list mr-0 ml-0 ${showAvailableOptions[quote.ids[0]] ? 'vehicles-list-expanded' : ''}`}>
                    <div className={`booking-options w-100 ${showAvailableOptions[quote.ids[0]] ? 'show-options' : ''}`} >
                      <ul className="pl-0">
                        <li className="option">
                          <AddonContainer
                            travelAddon={addonsAlphabeticallySorted[meetGreetAddonIndex]}
                            quote={quote}
                            possibleSelectionAmount={possibleSelectionAmount}
                            selectAddonAmount={selectAddonAmount}
                            selectAddon={selectAddon} />
                        </li>
                        {addonsAlphabeticallySorted.map((travelAddon: any, index: number) => {
                          if (index === meetGreetAddonIndex) {
                            return;
                          }
                          return (
                            <li className="option" key={index} style={{ color: partnerStylingOverrides?.['whitelabel.addons.textColor'] }}>
                              <AddonContainer
                                travelAddon={travelAddon}
                                quote={quote}
                                possibleSelectionAmount={possibleSelectionAmount}
                                selectAddonAmount={selectAddonAmount}
                                selectAddon={selectAddon} />
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </CRow>
                </div>
              }
            </div>
          );
        })}
        {((!quotes?.length && !isLoading) || error) && <div className="no-price">{error}</div>}
      </CCol>
    </>
  );
};

export default VehicleSelection;
